export const dockMaster: KV = {
  'CMCS1': {
    'status': '会員',
    'no_schedule' : [],
    'priority': {
      'M': ['MRI', 'ES'],
      'F': ['MRI', 'ES', 'MMG', 'ABUS', 'US']
    }
  },
  'CMCS2': {
    'status': '会員',
    'no_schedule' : ['FS', 'ECG'],
    'priority': {
      'M': ['US', 'DEXA', 'MRI', 'PET-CT'],
      'F': ['US', 'DEXA', 'MRI', 'PET-CT']
    }
  },
  'CMCS全': {
    'status': '会員',
    'no_schedule' : ['FS', 'ECG'],
    'priority': {
      'M': ['MRI', 'US', 'ES', 'DEXA', 'PET-CT'],
      'F': ['MMG', 'ABUS', 'MRI', 'US', 'ES', 'DEXA', 'PET-CT']
    }
  },
  '総合がんPETドック': {
    'status': '高額ドック',
    'no_schedule' : [],
    'priority': {
      'M': ['US', 'MRI', 'PET-CT'],
      'F': ['US', 'MRI', 'PET-CT']
    }
  },
  '【VIP】総合がんPETドック': {
    'status': 'VIP',
    'no_schedule' : [],
    'priority': {
      'M': ['US', 'MRI', 'PET-CT'],
      'F': ['US', 'MRI', 'PET-CT']
    }
  },
  'がんPETドック': {
    'status': '高額ドック',
    'no_schedule' : [],
    'priority': {
      'M': ['PET-CT'],
      'F': ['PET-CT']
    }
  },
  '【VIP】がんPETドック': {
    'status': 'VIP',
    'no_schedule' : [],
    'priority': {
      'M': ['PET-CT'],
      'F': ['PET-CT']
    }
  },
  'スーパードック': {
    'status': '一般ドック',
    'no_schedule' : ['FS', 'ECG'],
    'priority': {
      'M': ['US', 'XP', 'PET-CT', 'MRI', 'ES'],
      'F': ['US', 'XP', 'PET-CT', 'MRI', 'ES']
    }
  },
  'レディースドック': {
    'status': 'レディース',
    'no_schedule' : ['QUS'],
    'priority': {
      'M': [],
      'F': ['MMG', 'ABUS', 'US(L)']
    }
  },
  'ブレストドック': {
    'status': 'レディース',
    'no_schedule' : [],
    'priority': {
      'M': [],
      'F': ['MMG', 'ABUS']
    }
  },
  '一般ドック': {
    'status': '一般ドック',
    'no_schedule' : ['FS', 'ECG'],
    'priority': {
      'M': ['US', 'XP', 'ES'],
      'F': ['US', 'XP', 'ES']
    }
  },
}

// TODO: タック　問診のキーワードを協議する
export const nonScheduleModality = ["XP", "DEXA", "DEXA(US)", "DEXA(QUS)", "QUS", "ECG", "FS", "RG", "QST", "UCT"]

export const parseModalityAll = (modalityAll: string) => {
  let match
  const regex = /(PET-CT_\d+)(.*?)(単純|造影)/g;
  let modality, body2, contrast;
  while ((match = regex.exec(modalityAll)) !== null) {
    [, modality, body2, contrast] = match;
  }
  return [modality, body2, contrast]
}